body {
  margin: 0;
  padding: 0;
}

#unified-root {
  height: 100%
}

.main-content-area {
  display: flex;
  background: var(--color-p1-3);
  z-index: 0;
}

#unified-root .main-navigation {
  z-index: 10;
}

#unified-root .global-notifications-bar.global-notifications-bar--enableNotifications {
  display: none;
}

.nav-onboarding-get-started-tooltip-container,
.unified-onboarding--get-started-tooltip-container {
  z-index: 2;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tide-chip-icon {
  vertical-align: top !important;
}
